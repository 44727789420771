<template>
  <div id="uabout">
    <div class="fullWidth atLeft">
      <h2>About</h2>
    </div>

    <div class="fullWidth atLeft">
      <img :src="picture" alt />
    </div>
    <div class="fullWidth atLeft">
      <input
        type="file"
        id="profilePic"
        accept="image/*"
        @change="previewImage"
      />
    </div>
    <div>
      <vue-editor v-model="desc"></vue-editor>
    </div>

    <div class="btnArea atLeft">
      <button class="btn" @click="post">Write</button>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "about",
  data: () => ({
    desc: "",
    imageData: null,
    picture: null,
    uploadValue: 0,
  }),
  components: {
    VueEditor,
  },
  mounted() {
    this.get();
  },
  methods: {
    async post() {
      await this.$firebase
        .firestore()
        .collection("About")
        .doc("4VZ5rTVkuJ3lPuOgNCzh")
        .set(
          {
            desc: this.desc,
            picture: this.picture,
          },
          { merge: true }
        );
      alert("修正完了");
      this.$router.push("/uAbout");
    },
    async get() {
      const r = await this.$firebase
        .firestore()
        .collection("About")
        .doc("4VZ5rTVkuJ3lPuOgNCzh")
        .get();

      this.desc = r.data().desc;
      this.picture = r.data().picture;
    },
    previewImage(e) {
      this.uploadValue = 0;
      this.picture = null;
      const rightNow = Date.parse(new Date());
      this.imageData = e.target.files[0];
      const storageRef = this.$firebase
        .storage()
        .ref(`${rightNow + "-" + this.imageData.name}`)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.picture = url;
          });
        }
      );
    },
  },
};
</script>

<style>
.ghost {
  display: none;
}
</style>

<style>
#uabout {
  display: block;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.atLeft {
  justify-content: left;
  text-align: left;
}
.bigText {
  width: 80%;
  height: 80%;
  font-family: serif;
  padding: 10px;
}
.fullWidth {
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.text {
  /* min-height: 300px;
  min-width: 800px; */
  display: block;
}
.btn {
  margin: 10px;
  min-width: 70px;
  height: 50px;
  font-family: serif;
  font-size: 15px;
  border-radius: 8px;
  background-color: rgb(127, 127, 255);
  color: white;
}

.btnArea {
  display: block;
  margin-top: 30px;
}
</style>